<template>
	<div class="BuyBox">
		<div class="buy-box_mobile" v-if="is_mobile">
			<div class="buy-box_container">
				<div class="title">{{ $t('Buy BOX') }}</div>

				<div class="balance-box">
					<img class="balance-box_bg" src="@/assets/buy_box_balance.png" alt="">
					<img class="balance-box_img" src="@/assets/buy_box_icon1.png" alt="">
					<div class="balance-content">
						<div class="balance-content_head">
							<span>{{ $t('YOUR BALANCE') }}</span>
							<img src="@/assets/BUSD.png" alt="">
						</div>
						<div class="balance-value">{{ usdtBalance }}</div>
					</div>
				</div>

				<div class="box-price_box">
					<div class="box-price_head">
						<span>{{ $t('1 BOX COST') }}</span>
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small class="ml-2" v-bind="attrs" v-on="on">
									<v-icon color="#EB6930" size="20">mdi-help-circle</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('For every gacha sold, the sales price increases by 0.000005 BUSD') }}</span>
						</v-tooltip>
					</div>
					<div class="box-price">{{ boxPrice }}</div>
				</div>

				<div class="buy-input_box">
					<div class="buy-input">
						<div class="label">{{ $t('NUM OF BOXES') }}</div>
						<input type="number" placeholder="0" @input="calcBoxPrice" v-model="box_volume">
					</div>
					<div class="buy-total">
						<div class="label">{{ $t('TOTAL COST') }}</div>
						<div class="buy-total_content">
							<img src="@/assets/BUSD.png" alt="">
							<span>{{ calc_box_price }}</span>
						</div>
					</div>
				</div>

				<v-btn
						color="#EB6930"
						style="height: 44px !important;"
						large
						rounded
						block
						:loading="buy_loading"
						@click="buyBox"
				>{{ usdt_approve ? 'BUY' : 'APPROVE' }}
					<template v-slot:loader>
						<span>{{ usdt_approve ? $t('BUYING...') : $t('APPROVED...') }}</span>
					</template>
				</v-btn>
			</div>

			<div class="open-box_container">
				<div class="title">{{ $t('My BOX') }}</div>

				<div class="box-quantity_box">
					<img src="@/assets/pic_mybox.png" alt="">
					<div>{{ $t('YOUR BOXES') }}</div>
					<div>{{ your_pending_boxes }}</div>
				</div>

				<div class="open-box_input">
					<div class="open-box_label">
						<span>{{ $t('NUM OF BOXES') }}</span>
					</div>

					<input type="number" placeholder="0" v-model="open_box_volume">

					<v-btn
							color="#EB6930"
							style="height: 44px !important;"
							large
							rounded
							block
							:loading="open_loading"
							@click="openBox"
					>{{ $t('OPEN NUM BOX', {num: open_box_volume || 0}) }}
						<template v-slot:loader>
							<span>{{ $t('Opening...') }}</span>
						</template>
					</v-btn>
				</div>
			</div>
		</div>

		<div class="BuyBox-head" v-if="!is_mobile">
			<div class="BuyBox-head__title">{{ $t('Buy BOX') }}</div>
			<div class="BuyBox-head__content">
				<div class="BuyBox-head__content--left">
					<div class="d-flex align-center">
						<span class="title">{{ $t('YOUR BALANCE') }}</span>
						<img class="logo" src="@/assets/BUSD.png" alt="">
					</div>
					<div class="number">{{ usdtBalance }}</div>
					<img class="right-img__box" src="@/assets/buy_box_icon1.png" width="138" alt="">
				</div>
				<div class="BuyBox-head__content--right">
					<div class="d-flex align-center">
						<span>{{ $t('1 BOX COST') }}</span>
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small class="ml-2" v-bind="attrs" v-on="on">
									<v-icon color="#EB6930">mdi-help-circle-outline</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('For every gacha sold, the sales price increases by 0.000005 BUSD') }}</span>
						</v-tooltip>
					</div>
					<div class="amount">{{ boxPrice }}</div>
				</div>
			</div>
			<div class="BuyBox-head__foot">
				<div class="number-box">
					<div class="number-title">{{ $t('NUM OF BOXES') }}</div>
					<div class="number-input">
						<input type="number" @input="calcBoxPrice" v-model="box_volume" placeholder="0">
					</div>
				</div>
				<div class="number-box">
					<div class="number-title">{{ $t('TOTAL COST') }}</div>
					<div class="number-input">
						<img src="@/assets/BUSD.png" alt="">
						<input type="number" :value="calc_box_price" disabled>
					</div>
				</div>
				<v-btn
					color="#EB6930"
					style="width: 129px;height: 52px !important;"
					large
					rounded
					:loading="buy_loading"
					@click="buyBox"
				>{{ usdt_approve ? $t('BUY') : $t('APPROVE') }}
					<template v-slot:loader>
						<span>{{ usdt_approve ? $t('BUYING...') : $t('APPROVED...') }}</span>
					</template>
				</v-btn>
			</div>
		</div>

		<div class="BuyBox-foot" v-if="!is_mobile">
			<div class="BuyBox-foot__content">
				<div class="BuyBox-foot__left">
					<img src="@/assets/pic_mybox.png" alt="">
				</div>
				<div class="BuyBox-foot__right">
					<div class="title">{{ $t('My BOX') }}</div>
					<div class="box-number">
						<div class="box-number__title">{{ $t('YOUR BOXES') }}</div>
						<div class="box-number__value">{{ your_pending_boxes }}</div>
					</div>
					<div class="foot">
						<div class="d-flex align-center">
							<span class="foot-title">{{ $t('NUM OF BOXES') }}</span>
						</div>
						<div class="foot-content">
							<div class="input-box">
								<input type="number" v-model="open_box_volume" placeholder="0">
							</div>
							<v-btn
								color="#EB6930"
								style="width: 184px;height: 52px !important;"
								large
								rounded
								:loading="open_loading"
								@click="openBox"
							>{{ $t('OPEN NUM BOX', {num: open_box_volume || 0}) }}
								<template v-slot:loader>
									<span>{{ $t('Opening...') }}</span>
								</template>
							</v-btn>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-dialog v-model="open_dialog" max-width="1031px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('Congregations') }}</span>
					<v-btn @click="open_dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content">
					<div class="card-box">
						<div class="card-container" v-for="(item, i) in open_list" :key="item.id">
							<div class="card" :class="{'flip-vertical-right': open_index > i}">
								<div class="card-front">
									<img class="open-card_img" src="@/assets/close_bg.png" alt="">
								</div>
								<div class="card-back">
									<div class="card-front_info">
										<div class="d-flex align-center justify-space-between">
											<div>{{ type_list[item.desc.split('-')[0]] }}</div>
											<img class="combination-img" src="@/assets/combination_tag.png" alt="" v-show="cards[item.desc].combined_list.length > 0">
										</div>
										<div class="text-right open-card_name">
											<div>{{ cards[item.desc].zhHans }}</div>
											<div>{{ cards[item.desc].en }}</div>
										</div>
									</div>
									<img class="open-card_img card-front_img" :src="getCardImgUrl(item.desc)" alt="">
									<img class="open-card_img card-front_bg" src="@/assets/open-bg.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-dialog>
		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { cardFactory, usdtContract, factory_address, myCards, getBoxPrice, getUsdtAmt } from '@/utils/cardFactory'
import { mapState, mapMutations } from 'vuex'
import eventBus from '@/utils/eventBus'
import { getCardImgUrl } from '@/utils/tools'
import cards from '@/data/card'

export default {
	name: 'BuyBox',
	data () {
		return {
			BNB_balance: 0,
			box_volume: 1,
			open_box_volume: '',
			calc_box_price: '0.00',
			usdt_approve: false,
			your_pending_boxes: 0,
			usdtBalance: 0,
			// 我的卡牌数组
			// {
			// 	tokenId: nft token id
			// 	quality: 等级
			// 	index: 第几个
			// }
			your_cards: [],
			boxPrice: '0.00',
			open_loading: false,
			buy_loading: false,
			open_dialog: false,
			open_index: 0,
			open_list: [],
			timer: null,
			type_list: {
				1: 'N',
				2: 'R',
				3: 'SR',
				4: 'SSR',
				5: 'UR',
				9: 'GOD',
			},
			cards,
			overlay: false,
			invitation: null,
		}
	},
	created () {
		this.invitation = this.$route.query.invitation
	},
	mounted () {
		console.log(cardFactory.methods)
		if (this.ADDRESS) {
			this.getUsdtBalance()
			this.pendingBoxes()
			this.myCards()
			this.refreshBoxPrice()
			this.calcBoxPrice()
			this.usdtAllowance()
		}
		// 添加 eventBus 监听器
		eventBus.$on('connect-event', (res) => {
			console.log('connect-event', res)
			this.getUsdtBalance()
			this.pendingBoxes()
			this.myCards()
			this.refreshBoxPrice()
			this.calcBoxPrice()
			this.usdtAllowance()
		})
	},
	beforeDestroy () {
		// 移除 eventBus 监听器
		eventBus.$off('connect-event')
	},
	computed: {
		...mapState(['ADDRESS']),
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	methods: {
		...mapMutations(['SET_OVERLAY']),
		async calcBoxPrice () {
			this.calc_box_price = await getBoxPrice(this.box_volume)
		},
		async getBalance () {
			const data = await this.web3.eth.getBalance(this.ADDRESS)
			this.BNB_balance = Number(this.web3.utils.fromWei(data, 'ether')).toFixed(8)
		},
		async getUsdtBalance () {
			this.usdtBalance = await getUsdtAmt(this.ADDRESS)
		},
		async refreshBoxPrice () {
			this.boxPrice = await getBoxPrice(1)
		},
		// 购买盲盒
		async buyBox () {
			if (this.usdt_approve) {
				this.buy_loading = true
				try {
					await cardFactory.methods.buyBox(this.box_volume, this.invitation ? this.invitation : '0x0000000000000000000000000000000000000000').send({ from: this.ADDRESS })
					this.pendingBoxes()
					this.getUsdtBalance()
					this.buy_loading = false
					this.$toast.success(this.$t('Successful purchase of num blind boxes', { num: this.box_volume }))
				} catch (error) {
					this.buy_loading = false
				}
			} else {
				this.usdtApprove()
			}
		},
		getCardInfo (tokenId) {
			tokenId = Number(tokenId)
			const index = tokenId % 256
			const quality = (tokenId >>> 8) % 256
			return `${quality}-${index}`
		},
		// 开启盲盒
		openBox () {
			if (this.open_box_volume === '' || Number(this.open_box_volume) < 1) return
			if (this.open_box_volume > 50) {
				return this.$toast.warning(this.$t('The maximum number of open boxes is 50'))
			}
			this.overlay = true
			if (this.open_box_volume > 0) {
				this.open_loading = true
				cardFactory.methods.openBox(this.open_box_volume).send({ from: this.ADDRESS }).then(res => {
					console.log(res)
					const { events } = res
					const { NewCard } = events
					const ids = []
					if (NewCard.length > 1) {
						for (const event of NewCard) {
							const { returnValues } = event
							ids.push({
								id: returnValues.tokenId,
								desc: this.getCardInfo(returnValues.tokenId),
							})
						}
					} else {
						const { returnValues } = NewCard
						ids.push({
							id: returnValues.tokenId,
							desc: this.getCardInfo(returnValues.tokenId),
						})
					}
					/**
					 * 打印的抽奖结果
					 */
					console.info('tokenId:', ids)
					this.open_list = ids
					this.open_dialog = true
					this.open_index = 0
					this.overlay = false
					this.startCountdown()
					this.$toast.success(this.$t('Successfully opened num blind boxes', { num: this.open_box_volume }))
					this.pendingBoxes()
					this.open_loading = false
					this.open_box_volume = ''
				}).catch(err => {
					console.info(err)
					this.open_loading = false
					this.overlay = false
				})
			}
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		startCountdown () {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.open_index++
				if (this.open_index <= this.open_list.length) {
					this.startCountdown()
				}
			}, 400)
		},
		// 待开启盲盒数量
		pendingBoxes () {
			cardFactory.methods.pendingBoxes(this.ADDRESS).call().then(res => {
				console.info(res)
				this.your_pending_boxes = +res
			})
		},
		async myCards () {
			try {
				const res = await myCards(this.ADDRESS)
				this.your_cards = [...res]
			} catch (e) {
			}
		},
		// 授权
		usdtApprove () {
			this.buy_loading = true
			usdtContract.methods.approve(factory_address, '0xffffffffffffffffffffffffffffffffffffffff').send({ from: this.ADDRESS }).then(res => {
				this.usdt_approve = true
				console.log('授权', res)
				this.buy_loading = false
			}).catch(err => {
				console.info(err)
				this.buy_loading = false
			})
		},
		// 查询是否授权
		async usdtAllowance () {
			const res = await usdtContract.methods.allowance(this.ADDRESS, factory_address).call()
			console.log(res)
			if (Number(res) > 0) {
				this.usdt_approve = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.BuyBox {
	.buy-box_mobile {
		.open-box_input {
			.open-box_label {
				span {
					font-weight: 500;
					font-size: 12px;
					line-height: 28px;
				}
				padding: 30px 0 20px;
			}
			input {
				width: 100%;
				height: 38px;
				background: #495058;
				border-radius: 20px;
				padding: 0 18px;
				color: #FFFFFF;
				margin-bottom: 30px;
			}
		}
		.buy-box_container {
			padding: 40px 18px;
		}
		.open-box_container {
			.box-quantity_box {
				img {
					position: absolute;
					left: 0;
					top: -50px;
					width: 100px;
				}
				div {
					&:first-child {
						font-weight: 500;
						font-size: 12px;
						line-height: 28px;
					}
					&:last-child {
						font-weight: bold;
						font-size: 18px;
						line-height: 27px;
					}
				}
				height: 95px;
				background: #565C65;
				border-radius: 10px;
				padding: 18px;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-between;
				position: relative;
			}
			.title {
				text-align: right;
			}
			padding: 18px;
			background-color: #2D3339;
		}
		.buy-input_box {
			.buy-total {
				.buy-total_content {
					display: flex;
					align-items: center;
					height: 38px;
					background: #495058;
					border-radius: 26px;
					padding: 0 18px;
				}
				img {
					width: 20px;
					height: 20px;
					margin-right: 6px;
				}
				flex: 1;
			}
			.buy-input {
				input {
					width: 101px;
					height: 38px;
					background: #495058;
					border-radius: 26px;
					padding: 0 18px;
					color: #FFFFFF;
				}
				margin-right: 38px;
			}
			.label {
				font-weight: bold;
				font-size: 12px;
				line-height: 18px;
				color: #E8E8E8;
				margin-bottom: 10px;
			}
			display: flex;
			margin-bottom: 30px;
		}
		.box-price_box {
			.box-price_head {
				span {
					font-weight: 500;
					font-size: 12px;
					line-height: 28px;
				}
			}
			.box-price {
				font-weight: bold;
				font-size: 20px;
				line-height: 30px;
				color: #EB6930;
				margin-top: 10px;
			}
			background: #2D3339;
			border-radius: 20px;
			margin-top: 20px;
			margin-bottom: 30px;
			padding: 18px;
		}
		.balance-value {
			font-weight: bold;
			font-size: 22px;
			line-height: 32px;
		}
		.balance-content_head {
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 17px;
				margin-right: 8px;
			}
			img {
				width: 18px;
			}
			display: flex;
			align-items: center;
		}
		.balance-box_img {
			position: absolute;
			right: 0;
			top: -55px;
			width: 120px;
		}
		.balance-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 18px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.title {
			margin-bottom: 20px;
		}
		.balance-box {
			.balance-box_bg {
				width: 100%;
			}
			position: relative;
		}
	}

	&-foot {
		&__content {
			max-width: 948px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&__left {
			img {
				max-width: 450px;
			}
		}
		&__right {
			.foot {
				&-content {
					.input-box {
						input {
							flex: 1;
							font-weight: bold;
							font-size: 24px;
							line-height: 36px;
							color: #E8E8E8;
						}

						width: 278px;
						height: 52px;
						background: #495058;
						border-radius: 20px;
						margin-right: 37px;
						display: flex;
						align-items: center;
						padding: 0 23px;
					}

					margin-top: 16px;
					display: flex;
					align-items: center;
				}

				&-title {
					font-weight: 500;
					font-size: 14px;
					line-height: 28px;
					color: #FFFFFF;
				}
			}

			.box-number {
				&__value {
					font-weight: bold;
					font-size: 36px;
					line-height: 54px;
					color: #FFFFFF;
				}

				&__title {
					font-weight: 500;
					font-size: 14px;
					line-height: 28px;
					color: #FFFFFF;
				}

				width: 498px;
				height: 156px;
				background: #565C65;
				border-radius: 20px;
				margin-top: 40px;
				margin-bottom: 70px;
				padding: 30px;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-between;
			}

			.title {
				font-weight: bold;
				font-size: 24px;
				line-height: 36px;
				text-align: right;
				color: #E8E8E8;
			}
		}

		//padding: 100px 130px;
		background-color: #2D3339;
	}

	&-head {
		&__foot {
			.number {
				&-box {
					margin-right: 66px;
				}

				&-input {
					img {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						margin: 6px;
					}

					input {
						flex: 1;
						font-weight: bold;
						font-size: 24px;
						line-height: 36px;
						color: #E8E8E8;
						padding: 0 20px;
					}

					width: 260px;
					height: 52px;
					background: #495058;
					border-radius: 26px;
					display: flex;
					align-items: center;
					margin-top: 16px;
				}
			}

			margin-top: 60px;
			display: flex;
			align-items: flex-end;
		}

		&__content {
			&--left {
				.right-img__box {
					position: absolute;
					right: -8px;
					top: -50%;
				}

				.logo {
					width: 28px;
					height: 28px;
					border-radius: 50%;
					margin-left: 12px;
				}

				.number {
					font-weight: bold;
					font-size: 36px;
					line-height: 54px;
					color: #FFFFFF;
				}

				.title {
					font-weight: 500;
					font-size: 14px;
					line-height: 28px;
					color: #FFFFFF;
				}

				width: 574px;
				height: 156px;
				border-radius: 20px;
				background: url("../../assets/buy_box_balance.png");
				background-size: cover;
				margin-right: 30px;
				padding: 20px 30px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				position: relative;
			}

			&--right {
				.amount {
					font-weight: bold;
					font-size: 36px;
					line-height: 54px;
					color: #EB6930;
				}

				width: 253px;
				height: 156px;
				background: #2D3339;
				border-radius: 20px;
				padding: 25px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			display: flex;
			align-items: center;
			margin-top: 40px;
		}

		&__title {
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			color: #E8E8E8;
		}

		padding: 80px 0;
		max-width: 948px;
		margin: 0 auto;
	}

	background-color: #202831;
}
</style>
